import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

export const Wrap = styled.div`
  position: relative;
  min-height: 100vh;
  padding: 100px 0;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    min-height: auto;
    padding: 30px 0;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #484271;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.overlayBg};
    opacity: 0.7;
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    width: 40%;
    height: 100%;
    right: 0;
    background: linear-gradient(
      90deg,
      rgb(72 66 113 / 3%) 0%,
      rgba(72, 66, 113, 0.9066001400560224) 36%,
      rgba(72, 66, 113, 1) 100%
    );
  }
  .gatsby-image-wrapper {
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints['0']}) {
    picture {
      display: none;
    }
  }
`;

export const BackgroundImage = styled(StaticImage)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -10px;
`;

export const Column = styled.div`
  flex: 1 0 50%;
  max-width: 50%;
  padding: 0 10px;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  padding: 30px 0;
`;

export const LogoLink = styled.a`
  display: inline-block;
  margin-bottom: 50px;
`;
