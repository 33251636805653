interface Props {
  id: number;
  text: string;
  isBenefit?: boolean;
}

export const benefits: Array<Props> = [
  {
    id: 1,
    text: 'Seja qual for a necessidade nós garantimos as melhores soluções para que seu trabalho acadêmico seja feito com excelência e dentro do prazo',
    isBenefit: true,
  },
  {
    id: 2,
    text: 'Outras prioridades podem tirar o seu foco, fazendo com que você não seja apto a elaborar no prazo determinado e com a qualidade exigida o seu trabalho acadêmico',
  },
  {
    id: 3,
    text: 'Nossos especialistas elaboram trabalhos de qualquer complexidade em pouco tempo, pois são altamente qualificados para realização de pesquisas científicas e escrita acadêmica',
    isBenefit: true,
  },
  {
    id: 4,
    text: 'O processo de elaboração de um trabalho acadêmico requer muito preparo e tempo. Planejamento, pesquisa científica, interpretação dos dados e formatação dentro de normas específicas como as da ABNT são exigências básicas',
  },
  {
    id: 5,
    text: 'Os especialistas elaboram e cuidam do seu trabalho acadêmico como se fossem deles. Garantimos um resultado impecável acarretando em sua aprovação',
    isBenefit: true,
  },
  {
    id: 6,
    text: 'Não corra o risco de ser reprovado. Coisas simples como não cumprir o prazo, fugir do tema, não seguir as normas estipuladas ou elaborar um trabalho que possa conter plágio causam a reprovação',
  },
];
