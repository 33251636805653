import styled from 'styled-components';

export const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  cursor: pointer;
`;
export const Icon = styled.span`
  position: absolute;
  width: 10px;
  height: 10px;
  top: 15px;
  right: 10px;
  cursor: pointer;
  & > svg {
    transform: rotate(90deg);
  }
`;

export const OptionList = styled.ul<{ isOpen: boolean }>`
  display: block;
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
  max-height: ${({ isOpen }) => (isOpen ? '300px' : '0')};
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
  border-radius: ${({ theme }) => theme.borderRadius[0]};
  list-style: none;
  overflow: auto;
  transition: 0.25s max-height ease-in-out;
`;

export const Item = styled.li`
  padding: 10px;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.textDefault};
  cursor: pointer;
`;