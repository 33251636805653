import * as React from 'react';

const WorkingHoursContext = React.createContext(false);

export const WorkingHoursProvider = ({ children }) => {
  const [isWorkHours, setIsWorkHours] = React.useState(false);
  const value = isWorkHours;

  const checkIsWorking = () => {
    const brTime = new Date(
      new Date().toLocaleString('en', {
        timeZone: 'America/Sao_Paulo',
        hour12: false,
      })
    );
    const isWeekend = brTime.getDay() === 0 || brTime.getDay() === 6;

    const startHour = isWeekend ? 10 : 7;
    const endHour = isWeekend ? 18 : 22;

    const currentHour = brTime.getHours();

    if (new URLSearchParams(window.location.search).get('showChat')) {
      setIsWorkHours(true);
      return;
    }

    if (currentHour >= startHour && currentHour < endHour) {
      setIsWorkHours(true);
    }
  };

  React.useEffect(() => {
    checkIsWorking();
  }, []);

  return (
    <WorkingHoursContext.Provider value={value}>
      {children}
    </WorkingHoursContext.Provider>
  );
};

export const useWorkingHours = () => {
  const context = React.useContext(WorkingHoursContext);
  if (context === undefined) {
    throw new Error('useWorkingHours must be used within a ContextProvider');
  }
  return context;
};
