import * as React from 'react';
import { io } from 'socket.io-client';
import { StaticImage } from 'gatsby-plugin-image';
import { useMedia, useCookie } from 'hooks';
import { isBrowser, getCurrentTime } from 'helpers';
import { Flex, Box, Textarea, Text, Avatar } from 'ui/components';
import { IconDecline, IconSend } from 'images/icons';
import {
  Wrap,
  Form,
  Button,
  MobileButton,
  Close,
  MessagesWrap,
  ChatHeader,
  Message,
  MessageDesc,
  MessageExample,
  ExamplesWrap,
  Online,
  Time,
} from './styled';
import { useChatAnalytics } from '../../helpers/analytics';

interface MessageProps {
  text: string;
  author: string;
  time?: string;
  isOutgoing?: boolean;
}

const messageExamples = [
  { id: 1, text: 'Hello' },
  { id: 2, text: 'TCC' },
  { id: 3, text: 'Can you help me?' },
];
const chatDefaultParams = {
  api_token: process.env.GATSBY_USEDESK_API_TOKEN,
  company_id: '160821',
  channel_id: '30800',
};

export const Chat: React.FC = () => {
  const messageWrapRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const [socket, setSocket] = React.useState(null);
  const [messages, addMessages] = React.useState<MessageProps[]>([]);
  const [offsetTop, setOffsetTop] = React.useState<number>(0);
  const [chatId, setChatId] = useCookie('chatId');
  const isMobile = useMedia();
  const [isChatOpen, setIsChatOpen] = React.useState<boolean>(false);
  const [chatForm, setChatForm] = React.useState({ message: '' });

  const toggleChatOpen = () => {
    setIsChatOpen(prev => !prev);
  };
  const scrollToLastMsg = () => {
    const lastMessage = messageWrapRef.current.lastChild;
    lastMessage.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  const updateMessages = async (newMessage: MessageProps) => {
    await addMessages(prev => [...prev, newMessage]);
    scrollToLastMsg();
  };

  const onChange = e => {
    setChatForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const checkKey = e => {
    if (e.code === 'Enter' && !e.shiftKey) {
      onSubmit(e);
    }
  };

  const sendMessage = async (message: string) => {
    const outLength = messages.filter(item => item.isOutgoing).length;

    if (chatId) {
      socket.emit('sendMessage', {
        ...chatDefaultParams,
        chat_id: chatId,
        message: { text: message },
      });
    } else {
      const req = {
        ...chatDefaultParams,
        message: {
          text: message,
          from: {
            name: 'api landing',
          },
        },
      };
      socket.emit('createChat', { ...req });
    }

    useChatAnalytics(outLength);

    await updateMessages({
      text: message,
      author: 'You',
      time: getCurrentTime(),
      isOutgoing: true,
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (chatForm.message.length > 0) {
      sendMessage(chatForm.message);
      setChatForm({ message: '' });
      inputRef.current.focus();
    }
  };

  const calcChatOffset = () => {
    if (isMobile) {
      return;
    }
    if (isBrowser) {
      if (window.pageYOffset > document.documentElement.clientHeight - 200) {
        setOffsetTop(window.pageYOffset);
      } else {
        setOffsetTop(0);
      }
    }
  };

  // @ts-ignore
  React.useEffect(() => {
    const newSocket = io('https://katyaorlchatapp.herokuapp.com', {
      query: { chatId },
    });

    newSocket.on('message', async req => {
      updateMessages({
        text: req.text?.replace(/<[^>]*>?/gm, ''),
        author: req.ticket.author,
        time: req.ticket.published_at,
      });
    });

    newSocket.on('newChatId', id => {
      setChatId(id);
    });

    newSocket.on('messageSend', res => console.log(res));
    newSocket.on('onUpdate', res => console.log('from update', res));

    setSocket(newSocket);

    return () => newSocket.close();
  }, [chatId]);

  React.useEffect(() => {
    setTimeout(() => {
      updateMessages({
        text: 'Message for attention',
        author: 'Maria Paula',
        time: getCurrentTime(),
      });
    }, 6000);
  }, []);

  React.useEffect(() => {
    if (!isMobile && isBrowser) {
      setIsChatOpen(true);
      document.addEventListener('scroll', calcChatOffset, { passive: true });
    } else {
      setOffsetTop(0);
      setTimeout(() => {
        setIsChatOpen(true);
      }, 10000);
    }
    return () => {
      if (isBrowser) {
        document.removeEventListener('scroll', calcChatOffset);
      }
    };
  }, [isMobile]);

  return (
    <>
      {isMobile && (
        <MobileButton onClick={toggleChatOpen} isVisible={!isChatOpen}>
          <IconSend />
        </MobileButton>
      )}
      <Wrap top={offsetTop} isOpen={isChatOpen}>
        <ChatHeader>
          <Flex alignItems="center">
            <Avatar>
              <StaticImage
                src={`../../images/tutor-example.png`}
                alt="help chat"
              />
            </Avatar>
            <Box ml={3} color="textDark">
              <Flex alignItems="center" mb={2}>
                <Text m={0} mr={2} color="textDark">
                  Maria Paula
                </Text>
                <Online>online</Online>
              </Flex>
              <Text m={0} mb={1} level={6}>
                Support expert
              </Text>
              <Text m={0} level={6}>
                1,567 satisfied customers
              </Text>
            </Box>
          </Flex>

          {isMobile && (
            <Close onClick={toggleChatOpen}>
              <IconDecline />
            </Close>
          )}
        </ChatHeader>

        <MessagesWrap ref={messageWrapRef}>
          {messages.map((msg, index) => (
            <Flex
              width={1}
              mb={1}
              justifyContent={msg.isOutgoing ? 'end' : 'start'}
              alignItems="start"
              flexWrap="wrap"
              key={index}
            >
              {!msg.isOutgoing && (
                <Avatar variant="sm" isOnline>
                  <StaticImage
                    src={`../../images/tutor-example.png`}
                    alt="help chat"
                  />
                </Avatar>
              )}
              <Box>
                <MessageDesc isOutgoing={msg.isOutgoing}>
                  {msg.author} <Time>{msg.time}</Time>
                </MessageDesc>
                <Message variant={msg.isOutgoing ? 'outgoing' : 'incoming'}>
                  {msg.text}
                </Message>
              </Box>
            </Flex>
          ))}
          {messages.length > 0 &&
            messages.filter(item => item.isOutgoing).length === 0 && (
              <ExamplesWrap>
                {messageExamples.map(item => (
                  <MessageExample
                    key={item.id}
                    onClick={() => sendMessage(item.text)}
                  >
                    {item.text}
                  </MessageExample>
                ))}
              </ExamplesWrap>
            )}
        </MessagesWrap>

        <Form onSubmit={onSubmit}>
          <Textarea
            ref={inputRef}
            rows={2}
            name="message"
            placeholder="Type your message here"
            value={chatForm.message}
            onChange={onChange}
            onKeyPress={checkKey}
          />
          <Button type="submit">
            <IconSend />
          </Button>
        </Form>
      </Wrap>
    </>
  );
};
