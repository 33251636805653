import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { Header, Footer, CookieSettings } from 'components';
import { useDisclosure } from 'hooks';
import { Dialog } from 'ui/components';
import { theme } from 'ui/theme';
import { GlobalStyles } from 'ui/global';
import { getCookie, isCookieAllowed } from 'helpers';
import { WorkingHoursProvider } from 'hooks';

export const Layout = ({ children }) => {
  const dialog = useDisclosure();
  const [cookies, setCookies] = React.useState([]);

  const cookieHandler = cookies => {
    setCookies(cookies);
    dialog.onClose();
  };

  React.useEffect(() => {
    const isCookieAllowed = getCookie('allowed');

    if (!isCookieAllowed) {
      setTimeout(() => {
        dialog.onOpen();
      }, 1000);
    }
  }, []);

  React.useEffect(() => {
    if (isCookieAllowed('gtm') || cookies.includes('gtm')) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        // @ts-ignore
        j.async = true;
        // @ts-ignore
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-M6H9DSQ');
    }
  }, [cookies]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <WorkingHoursProvider>
        <Header />
        <main>{children}</main>
        <Footer />
        <Dialog
          isOpen={dialog.isOpen}
          onClose={dialog.onClose}
          closeOnOverlay={false}
        >
          <CookieSettings onSubmit={cookieHandler} />
        </Dialog>
      </WorkingHoursProvider>
    </ThemeProvider>
  );
};

export default Layout;
