import * as React from 'react';
import { ArrowBig, ArrowSmall } from 'images/icons';

export const ArrowBottom = props => (
  <div {...props}>
    <ArrowBig />
  </div>
);

export const ArrowSIde = props => (
  <div {...props}>
    <ArrowSmall />
  </div>
);