import { createGlobalStyle } from 'styled-components';
import { Theme } from 'models/theme';
import ProximaWoff from './fonts/proxima/proxima_regular.woff';
import ProximaWoff2 from './fonts/proxima/proxima_regular.woff2';

export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  @font-face {
    font-family: 'Proxima';
    src: local('Proxima Nova'), local('Proxima Nova Regular'),
    url(${ProximaWoff}) format('woff2'),
    url(${ProximaWoff2}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
    * {
      box-sizing: border-box;
    }
    html,body, #root{
        height: 100%;
    }
    body {
        margin: 0;
        padding: 0;
        color: ${({ theme }) => theme.colors.textDefault};
        background-color: ${({ theme }) =>
          theme.colors.backgroundAccentedLight};
        font-family: ${({ theme }) => theme.fontFamily};
    }

  div::-webkit-scrollbar, 
  textarea::-webkit-scrollbar{
    width: 5px;
    height: 5px;
  }
  div::-webkit-scrollbar-thumb, 
  textarea::-webkit-scrollbar-thumb{
    background: #C7C7C7;
    border-radius: 10px;
  }
  div::-webkit-scrollbar-thumb:hover, 
  textarea::-webkit-scrollbar-thumb:hover{
    background: #B3AFB3;
  }
  div::-webkit-scrollbar-track, 
  textarea::-webkit-scrollbar-track{
    background: #F0F0F0;
    border-radius: 10px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 1rem;
    }
  }

`;
