export const reviews = [
  {
    id: 1,
    title: 'Pedido #740174',
    theme: 'Direito / Resenha crítica',
    text: 'Gostaria de parabenizar a escritora Ana Paula. O atendimento foi impecável, o trabalho ficou com excelente qualidade e o prazo de entrega foi antecipado. Tudo no site foi muito organizado, e com certeza superaram minhas expectativas. Já indiquei para outras pessoas e vou continuar indicando. Vai ser você, Ana Paula, quem vai fazer meus próximos 6 trabalhos.',
  },
  {
    id: 2,
    title: 'Pedido #740174',
    theme: 'Direito / Resenha crítica',
    text: 'Tive a sorte de encontrar a Bruna. O que mais me impressionou nela foi sua organização, competência e atenção ao desenvolvimento do meu projeto. Ela me ajudou muito nesta fase de pós graduação, por conta de muito trabalho não tive tempo de completar o projeto, e a escritora faz como solicitado. Cumpriu a data estipulada de entrega. Para alunos que estão sempre com prazos apertados, não há nada melhor. O trabalho ficou perfeito. Bruna só tenho o que agradecer-te. Sua nota é 10!',
  },
  {
    id: 3,
    title: 'Pedido #740174',
    theme: 'Direito / Resenha crítica',
    text: 'Gostaria de parabenizar a escritora Ana Paula. O atendimento foi impecável, o trabalho ficou com excelente qualidade e o prazo de entrega foi antecipado. Tudo no site foi muito organizado, e com certeza superaram minhas expectativas. Já indiquei para outras pessoas e vou continuar indicando. Vai ser você, Ana Paula, quem vai fazer meus próximos 6 trabalhos.',
  },
];
