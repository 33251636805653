import { Option } from '../models';

export const workTypes: Array<Option> = [
  {
    id: '112',
    name: 'Revisão integrativa de literatura',
  },
  {
    id: '111',
    name: 'Revisão bibliografica',
  },
  {
    id: '110',
    name: 'PTI (Produção Textual Interdisciplinar)',
  },
  {
    id: '109',
    name: 'Projeto de pesquisa',
  },
  {
    id: '108',
    name: 'Projeto de intervenção',
  },
  {
    id: '107',
    name: 'Programação',
  },
  {
    id: '106',
    name: 'Procedimento Operacional Padrão',
  },
  {
    id: '105',
    name: 'Pré-projeto',
  },
  {
    id: '104',
    name: 'Pôster acadêmico',
  },
  {
    id: '103',
    name: 'Poemas',
  },
  {
    id: '102',
    name: 'Desenho',
  },
  {
    id: '101',
    name: 'Currículo',
  },
  {
    id: '100',
    name: 'Correção textual',
  },
  {
    id: '99',
    name: 'Carta argumentativa',
  },
  {
    id: '98',
    name: 'Calculos',
  },
  {
    id: '32',
    name: 'ABNT',
  },
  {
    id: '97',
    name: 'Biografia',
  },
  {
    id: '42',
    name: 'Análise',
  },
  {
    id: '28',
    name: 'Apresentação de Power Point',
  },
  {
    id: '96',
    name: 'Artigo cientifíco',
  },
  {
    id: '5',
    name: 'Artigo acadêmico',
  },
  {
    id: '22',
    name: 'Crítica Literária/Filme',
  },
  {
    id: '3',
    name: 'Dissertação de Mestrado',
  },
  {
    id: '19',
    name: 'Estudo de Caso',
  },
  {
    id: '36',
    name: 'Fichamento',
  },
  {
    id: '31',
    name: 'Monografia',
  },
  {
    id: '39',
    name: 'PIM – Projeto Integrado Multidisciplinar',
  },
  {
    id: '11',
    name: 'Plano de negócio',
  },
  {
    id: '40',
    name: 'Portfólio',
  },
  {
    id: '13',
    name: 'Produção de Conteúdo',
  },
  {
    id: '41',
    name: 'Projeto',
  },
  {
    id: '12',
    name: 'Projeto de Pesquisa',
  },
  {
    id: '34',
    name: 'Questões e Exercícios',
  },
  {
    id: '1',
    name: 'Redação',
  },
  {
    id: '14',
    name: 'Referencial teórico',
  },
  {
    id: '9',
    name: 'Relatório',
  },
  {
    id: '35',
    name: 'Resenha (Crítica)',
  },
  {
    id: '7',
    name: 'Resumo',
  },
  {
    id: '17',
    name: 'Revisão Textual',
  },
  {
    id: '4',
    name: 'TCC',
  },
  {
    id: '29',
    name: 'Tese de Doutorado',
  },
  {
    id: '2',
    name: 'Trabalho Acadêmico',
  },
  {
    id: '23',
    name: 'Trabalho de Matemática',
  },
  {
    id: '38',
    name: 'Tradução',
  },
  {
    id: '16',
    name: 'Outro tipo',
  },
];
