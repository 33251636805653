import * as React from 'react';
import { getCookie, setCookie } from 'helpers';

export const useCookie = (
  name: string
): [string | number, (value: string) => void] => {
  const [variable, setVariable] = React.useState<string | number>();
  const isCookieAllowed = getCookie('allowed')?.includes(name);

  const updateCookie = (value: string) => {
    setVariable(value);
    setCookie({ name, value, expire: 30 });
  };

  React.useEffect(() => {
    if (isCookieAllowed) {
      const fromCookie = getCookie(name);

      if (fromCookie) {
        setVariable(fromCookie);
      }
    }
  }, []);

  return [variable, updateCookie];
};
