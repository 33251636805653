import styled from 'styled-components';
import { color, ColorProps } from 'styled-system';

export const Section = styled.section<ColorProps>`
  padding: 100px 0 80px;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 50px 0 30px;
  }
  ${color}
`;
