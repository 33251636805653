import * as React from 'react';

export const useClickOutside = (ref, handler) => {
  const onClickOutside = e => {
    if (!ref.current || ref.current.contains(event.target)) {
      return;
    }
    handler(e);
  };

  React.useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside);
  });
};
