import * as React from 'react';
import { Wrap, Slide } from './styled';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}
export const Checkbox: React.FC<Props> = props => {
  return (
    <Wrap>
      <input id={props.name} {...props} />
      <Slide htmlFor={props.name} />
    </Wrap>
  );
};
