import * as React from 'react';
import { CustomSlider } from './styled';
import { ArrowBottom, ArrowSIde } from './arrows';

interface Props {
  slidesToShow?: number;
  isArrowsBottom?: boolean;
}

export const Carousel: React.FC<Props> = ({
  children,
  slidesToShow = 2,
  isArrowsBottom = true,
}) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    nextArrow: isArrowsBottom ? <ArrowBottom /> : <ArrowSIde />,
    prevArrow: isArrowsBottom ? <ArrowBottom /> : <ArrowSIde />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 2,
          centerMode: true,
          centerPadding: '35px',
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '35px',
          arrows: false,
        },
      },
    ],
  };
  return (
    <CustomSlider {...settings} slidesToShow={slidesToShow}>
      {children}
    </CustomSlider>
  );
};
