import * as React from 'react';
import { Wrap, Overlay, Content } from './styled';

interface Props {
  isOpen: boolean;
  closeOnOverlay?: boolean;
  onClose: () => void;
}

export const Dialog: React.FC<Props> = ({
  isOpen,
  onClose,
  children,
  closeOnOverlay = true,
}) => {
  const onOverlayClick = () => closeOnOverlay && onClose();
  return (
    <Wrap isVisible={isOpen}>
      <Overlay onClick={onOverlayClick} />
      <Content>{children}</Content>
    </Wrap>
  );
};
