import styled from 'styled-components';

export const Wrap = styled.div<{ isVisible: boolean }>`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 0.25s ease-in-out;
  z-index: 13;
`;
export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

export const Content = styled.div`
  max-width: 90%;
  max-height: 90%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
  z-index: 2;
`;
