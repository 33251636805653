import * as React from 'react';
import { benefits } from 'content/benefits';
import { BlockWithChat } from 'components/BlockWithChat';
import { IconSuccess, IconDecline } from 'images/icons';
import { useMedia} from 'hooks';
import {
  Container,
  Flex,
  Grid,
  Box,
  Status,
  Heading,
  Text,
} from 'ui/components';
import { Wrap, Overlay, Header, Tab } from './styled';

export const Benefits: React.FC = () => {
  const isMobile = useMedia();
  const [activeTab, setActiveTab] = React.useState('benefit');
  const [content, setContent] = React.useState(benefits);

  const getBenefits = () => {
    if (isMobile) {
      setContent(benefits.filter(item => item.isBenefit));
      setActiveTab('benefit');
    } else {
      setContent(benefits);
      setActiveTab('benefit');
    }
  };

  const getDisadvantages = () => {
    if (isMobile) {
      setContent(benefits.filter(item => !item.isBenefit));
      setActiveTab('disadvantage');
    }
  };

  React.useEffect(() => {
    getBenefits();
  }, [isMobile]);

  return (
    <Wrap>
      <Container>
        <Flex>
          <BlockWithChat>
            <Heading level="2">É mais fácil com a gente!</Heading>
            <Overlay>
              <Header>
                <Tab
                  level={3}
                  m={0}
                  p={3}
                  onClick={getBenefits}
                  isActive={activeTab === 'benefit'}
                >
                  Com Studybay
                </Tab>
                <Tab
                  level={3}
                  m={0}
                  p={3}
                  onClick={getDisadvantages}
                  isActive={activeTab === 'disadvantage'}
                >
                  Sozinho
                </Tab>
              </Header>

              <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridGap="2px">
                {content.map(item => (
                  <Box
                    key={item.id}
                    bg="backgroundDefault"
                    padding={3}
                    textAlign="center"
                  >
                    <Status
                      variant={item.isBenefit ? 'success' : 'error'}
                      m="auto"
                    >
                      {item.isBenefit ? <IconSuccess /> : <IconDecline />}
                    </Status>
                    <Text>{item.text}</Text>
                  </Box>
                ))}
              </Grid>
            </Overlay>
          </BlockWithChat>
        </Flex>
      </Container>
    </Wrap>
  );
};
