export const typography = {
  1: {
    fontSize: ['4','6'],
    lineHeight: '1.1',
    fontWeight: 'bold',
  },
  2: {
    fontSize: ['3', '5'],
    lineHeight: '1.5',
    fontWeight: 'bold',
    marginBottom: [2, 5],
  },
  3: {
    fontSize: ['3','4'],
    lineHeight: '1.5',
  },
  4: {
    fontSize: ['1','2'],
    lineHeight: '1.5',
  },
  5: {
    fontSize: '1',
    lineHeight: '1.5',
  },
  6: {
    fontSize: '0',
    lineHeight: '1.1',
    margin: '0',
  },
};
