import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Logo } from 'images/icons';
import { useWorkingHours } from 'hooks';
import { Heading, Text, Container, Grid, Box } from 'ui/components';
import { Form } from '../Form';
import { Chat } from '../Chat';
import { Wrap, Background, LogoLink } from './styled';

export const Header: React.FC = () => {
  const isWorkHours  = useWorkingHours();
  return (
    <>
      <Wrap>
        <Background>
          <StaticImage src="../../images/header-backgroung.jpeg" alt="alt" />
        </Background>
        <Container>
          <Grid
            gridGap={3}
            gridTemplateColumns={['1fr', '1fr 1fr']}
          >
            <Box>
              <LogoLink href="https://mystudybay.com.br/">
                <Logo />
              </LogoLink>
              <Heading level="1" color="textContrast">
                Seja aprovado com Studybay
              </Heading>
              <Text level={4} color="textContrast">
                Seu trabalho acadêmico bem elaborado em todosos detalhes. Nós
                cuidamos de tudo para que você não tenha nenhuma preocupação.
              </Text>
              <Form typeForm="br_ChatBotFormCustomer_1" />
            </Box>
            {isWorkHours && (
              <Box position="relative">
                <Chat />
              </Box>
            )}
          </Grid>
        </Container>
      </Wrap>
    </>
  );
};
