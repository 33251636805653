import * as React from 'react';
import { setCookie } from 'helpers';
import { Box, Grid, Text, Button, Checkbox } from 'ui/components';

interface Props {
  onSubmit: (cookies: string) => void;
}

interface CookieObject {
  value: string[];
  title: string;
  desc: string;
}

interface Cookies {
  general: CookieObject;
  marketing: CookieObject;
  other: CookieObject;
}

const cookiesByGroup: Cookies = {
  general: {
    value: ['allowed', 'io'],
    title: 'General',
    desc: 'Required cookies',
  },
  marketing: {
    value: ['refId', 'gtm', 'fb'],
    title: 'Marketing',
    desc: 'Cookies for marketing',
  },
  other: {
    value: ['chatId'],
    title: 'Other',
    desc: 'Other cookies',
  },
};

export const CookieSettings: React.FC<Props> = ({ onSubmit }) => {
  const [selected, setSelected] = React.useState<Array<string>>(
    Object.keys(cookiesByGroup)
  );

  const allowAll = () => {
    const allCookiesKeys = Object.values(cookiesByGroup).reduce(
      (acc, curr) => [...acc, ...curr.value],
      []
    );
    const cookiesString = JSON.stringify(allCookiesKeys);
    setCookie({ name: 'allowed', value: cookiesString, expire: 30 });
    onSubmit(cookiesString);
  };

  const allowByGroups = () => {
    const cookiesByKeys = Object.keys(cookiesByGroup).reduce(
      (acc, curr) =>
        selected.includes(curr) ? [...acc, ...cookiesByGroup[curr].value] : acc,
      []
    );
    const cookiesString = JSON.stringify(cookiesByKeys);
    setCookie({ name: 'allowed', value: cookiesString, expire: 30 });
    onSubmit(cookiesString);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(prev => {
      const type = e.target.name;
      return prev.includes(type)
        ? prev.filter(item => item !== type)
        : [...prev, type];
    });
  };

  return (
    <Box>
      <Text level={3}>Cookies to use</Text>
      <Box>
        {Object.entries(cookiesByGroup).map(([key, value]) => (
          <Grid
            key={key}
            gridTemplateColumns="1fr 1fr"
            gridGap={4}
            gridRowGap={0}
            mb={2}
          >
            <Text level={4} m={0}>
              {value.title}
            </Text>
            <Checkbox
              type="checkbox"
              name={key}
              onChange={onChange}
              checked={Boolean(selected.includes(key))}
              disabled={key === 'general'}
            />
            <Text mt={0}>{value.desc}</Text>
          </Grid>
        ))}
      </Box>
      <Grid gridTemplateColumns="1fr 1fr" gridGap={3}>
        <Button onClick={allowByGroups} variant="secondary">
          Only selected
        </Button>
        <Button onClick={allowAll}>Allow all</Button>
      </Grid>
    </Box>
  );
};
