import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  input {
    display: none;
  }
  input:checked + label {
    &:before {
      width: 100%;
      opacity: 1;
    }
    &:after {
      left: calc(100% - 30px);
    }
  }
  input:disabled + label {
    background-color: ${({ theme }) => theme.colors.backgroundDark};
    &:before {
      opacity: 0.5;
    }
  }
`;

export const Slide = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.backgroundAccented};
  border-radius: ${({ theme }) => theme.borderRadius['2']};
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.backgroundDark};
    border-radius: ${({ theme }) => theme.borderRadius['2']};
    opacity: 0;
    transition: width 0.25s, opacity 0.20s;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.backgroundAccented};
    box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.29);
    transition: opacity, left 0.25s;
  }
`;
