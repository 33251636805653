import * as React from 'react';
import { BlockWithChat } from 'components/BlockWithChat';
import {
  Section,
  Container,
  Carousel,
  Heading,
  Text,
} from 'ui/components';
import { IconQuotes } from 'images/icons';
import { Card, Quotes } from './styled';
import { reviews } from 'content/reviews';

export const Reviews = () => {
  return (
    <Section>
      <Container>
        <BlockWithChat>
          <Heading level="2">Avaliações dos nossos clientes</Heading>
          <Carousel slidesToShow={2}>
            {reviews.map(item => (
              <Card key={item.id}>
                <Quotes>
                  <IconQuotes />
                </Quotes>
                <Text>{item.title}</Text>
                <Text>{item.text}</Text>
              </Card>
            ))}
          </Carousel>
        </BlockWithChat>
      </Container>
    </Section>
  );
};
