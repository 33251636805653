import * as React from 'react';
import { Select, Input, Grid, Button } from 'ui/components';
import { workTypes } from 'content/workTypes';
import {
  validateEmail,
  isBrowser,
  sendAnalytics,
  sendPost,
  sortArrayByStringProp,
} from 'helpers';

interface FormProps {
  title: string;
  type: string;
  email: string;
  recieve_notifications: boolean;
}

interface Props {
  typeForm: string;
}

export const Form: React.FC<Props> = ({ typeForm }) => {
  const [data, setData] = React.useState<FormProps>({
    title: '',
    type: '4',
    email: '',
    recieve_notifications: false,
  });

  const [validation, setValidation] = React.useState({
    title: { message: '', isTouched: false },
    email: { message: '', isTouched: false },
  });

  const [typeList, setTypeList] = React.useState(
    sortArrayByStringProp(workTypes, 'name')
  );

  const validateForm = async () => {
    if (data.title.length === 0) {
      setValidation(prev => ({
        ...prev,
        title: { ...prev.title, message: 'Is required' },
      }));
    } else if (data.title.length > 50) {
      setValidation(prev => ({
        ...prev,
        title: { ...prev.title, message: 'Max 50 letters' },
      }));
    } else if (data.title.length > 0 && data.title.length < 50) {
      setValidation(prev => ({
        ...prev,
        title: { ...prev.title, message: '' },
      }));
    }

    if (data.email.length === 0) {
      setValidation(prev => ({
        ...prev,
        email: { ...prev.email, message: 'Is required' },
      }));
    } else {
      const isEmailValid = await validateEmail(data.email);

      if (!isEmailValid) {
        setValidation(prev => ({
          ...prev,
          email: { ...prev.email, message: 'Not valid' },
        }));
      } else {
        setValidation(prev => ({
          ...prev,
          email: { ...prev.email, message: '' },
        }));
      }
    }
    return !validation.email.message && !validation.title.message;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(state => ({ ...state, [e.target.name]: e.target.value }));

    setValidation(prev => ({
      ...prev,
      [e.target.name]: { ...prev[e.target.name], isTouched: true },
    }));
  };

  const setTouched = () => {
    setValidation(prev => ({
      ...prev,
      title: { ...prev.title, isTouched: true },
      email: { ...prev.email, isTouched: true },
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTouched();

    const isFormValid = await validateForm();

    if (isFormValid) {
      sendAnalytics(
        'userAction',
        'signUpCustomer',
        'success',
        'signUpCustomer'
      );
      e.target.submit();
    }
  };

  const loadTypes = async () => {
    const types = await sendPost('/ajax/getdictionarylist');
    if (types) {
      const sortedByName = sortArrayByStringProp(types.workTypes, 'name');
      setTypeList(sortedByName);
    }
  };

  React.useEffect(() => {
    loadTypes();
    if (isBrowser) {
      const type = new URL(document.location.href).searchParams.get('type_id');
      if (type) {
        setData(prev => ({ ...prev, type }));
      }
    }
  }, []);

  return (
    <form
      onSubmit={onSubmit}
      action="https://mystudybay.com.br/order/unregOrderShortForm/?"
      method="post"
    >
      <Grid gridGap={3} gridTemplateColumns={['1fr', '1fr 1fr']}>
        <input type="hidden" name="recieve_notifications" value="0" disabled />
        <input
          type="hidden"
          name="reg_url"
          value="http://studybaychat.com/"
          disabled
        />
        <input type="hidden" name="type_form" value={typeForm} />

        <Input
          value={data.title}
          name="title"
          onChange={onChange}
          onKeyUp={validateForm}
          placeholder="Tutilo"
          validation={validation.title}
        />

        <Select
          value={data.type}
          name="type"
          onChange={onChange}
          placeholder="Type"
          options={typeList}
        />

        <Input
          value={data.email}
          name="email"
          onChange={onChange}
          onKeyUp={validateForm}
          placeholder="E-mail"
          validation={validation.email}
        />

        <Button type="submit">Buscar Ajuda</Button>
      </Grid>
    </form>
  );
};
