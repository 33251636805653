import styled from 'styled-components';
import * as React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  validation?: {
    message: string;
    isTouched: boolean;
  };
}

const Wrap = styled.div`
  position: relative;
`;

const Control = styled.input`
  width: 100%;
  padding: 1rem;
  padding-right: 20px;
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
  border: 1px solid ${({ theme }) => theme.colors.borderDefault};
  color: ${({ theme }) => theme.colors.textDark};
  border-radius: ${({ theme }) => theme.borderRadius[0]};
  outline: none;
  &:focus {
    box-shadow: 0 0 3pt 2pt ${({ theme }) => theme.colors.borderFocused};
  }
`;

const ErrorText = styled.div`
  position: absolute;
  max-width: 300px;
  padding: 5px;
  right: 0;
  top: calc(100% + 3px);
  background-color: ${({ theme }) => theme.colors.UIError};
  color: ${({ theme }) => theme.colors.textContrast};
  border-radius: ${({ theme }) => theme.borderRadius[1]};
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.25s;
`;

const Error = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
  border: 1px solid ${({ theme }) => theme.colors.UIError};
  color: ${({ theme }) => theme.colors.UIError};
  border-radius: 50%;
  z-index: 2;
  &:before {
    content: '!';
    display: block;
    font-size: 1.3rem;
    font-weight: bold;
  }
  &:hover {
    ${ErrorText} {
      opacity: 1;
    }
  }
`;

export const Input: React.FC<Props> = ({ validation, ...rest }) => (
  <Wrap>
    <Control {...rest} />
    {validation?.message && validation?.isTouched && (
      <Error>
        <ErrorText>{validation.message}</ErrorText>
      </Error>
    )}
  </Wrap>
);
