import styled from 'styled-components';
import {
  space,
  color,
  layout,
  flexbox,
  grid,
  typography,
  position,
  PositionProps,
  SpaceProps,
  ColorProps,
  LayoutProps,
  FlexboxProps,
  GridProps,
  TypographyProps,
} from 'styled-system';

interface Props
  extends SpaceProps,
    ColorProps,
    PositionProps,
    LayoutProps,
    FlexboxProps,
    GridProps,
    TypographyProps {
  children: React.ReactNode;
}
export const Box = styled.div<Props>(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  space,
  color,
  layout,
  flexbox,
  grid,
  typography,
  position,
);
