import styled from 'styled-components';
import { variant, space } from 'styled-system';
import { Flex } from '../../ui/components';

interface Props {
  top: number;
  isOpen: boolean;
}

const messageVariants = {
  incoming: {
    bg: 'backgroundAccented',
    borderTopLeftRadius: '0',
    color: 'overlayBg',
  },
  outgoing: {
    bg: 'UIMessage',
    borderTopRightRadius: '0',
    ml: 'auto',
    color: 'textContrast',
  },
};

export const Wrap = styled.div.attrs<Props>(props => {
  if (props.top > 0) {
    return {
      style: {
        top: `${props.top}px`,
      },
    };
  }
})<Props>`
  width: ${({ top }: { top: number }) => (top > 0 ? '65%' : '100%')};
  position: absolute;
  max-height: 80vh;
  right: 0;
  transform: scale(${({ isOpen }) => (isOpen ? 1 : 0)});
  translate(${({ isOpen }) => (isOpen ? '0,0' : '65%,90%')});
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
  border-radius: ${({ theme }) => theme.borderRadius['2']};
  z-index: ${({ isOpen }) => (isOpen ? 11 : -1)};
  transition: top 0.2s, transform 0.25s ease;
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.29);
  overflow: hidden;
  
  @media(max-width: ${({ theme }) => theme.breakpoints[0]}){
    position: fixed;
    bottom: 20px;
  }
  @media(max-width: ${({ theme }) => theme.breakpoints[0]}) and (orientation: landscape) {
    height: 100%;
    max-height: 100%;
    top: 0;
    bottom: auto;
  }
`;

export const ChatHeader = styled.div`
  display: block;
  position: relative;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.backgroundAccentedLight};
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 2;
  }
  @media(max-width: ${({ theme }) => theme.breakpoints[0]}) and (orientation: landscape) {
    padding: 10px 30px;
  }
`;

export const Form = styled.form`
  position: relative;
  display: block;
  padding-bottom: 20px;
  padding-right: 80px;
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
  overflow: hidden;
  textarea,
  input {
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  @media(max-width: ${({ theme }) => theme.breakpoints[0]}) and (orientation: landscape) {
    min-height: 100px;
  }
`;

export const Button = styled.button`
  position: absolute;
  display: flex;
  width: 50px;
  height: 50px;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.UISuccess};
  color: ${({ theme }) => theme.colors.textContrast};
  border-radius: 50%;
  border: 1px solid transparent;
  transition: opacity .25s ease-in;
  cursor: pointer;
  svg {
    width: 30px;
    height: 30px;
  }
  &:hover,
  &:focus {
    outline: none;
    opacity: .8;
    border: 1px solid ${({ theme }) => theme.colors.UISuccessBg};
  }
`;
export const MobileButton = styled(Button)<{ isVisible: boolean }>`
  position: fixed;
  top: auto;
  bottom: 8%;
  right: 10%;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  z-index: 10;
`;

export const Close = styled.span`
  position: absolute;
  top: 50%;
  right: 20px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  svg path {
    fill: ${({ theme }) => theme.colors.textContrast};
  }
`;

export const MessagesWrap = styled.div`
  position: relative;
  height: 500px;
  max-height: 35vh;
  overflow-y: auto;
  padding: 30px 20px;
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderDefault};
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    max-height: 50vh;
  }
  @media(max-width: ${({ theme }) => theme.breakpoints[0]}) and (orientation: landscape) {
    max-height: 45vh;
  }
`;

export const Message = styled.div<{ variant: string }>`
  margin: ${({ theme }) => theme.space['1']}px;
  padding: ${({ theme }) => theme.space['3']}px;
  border-radius: ${({ theme }) => theme.borderRadius['2']};
  ${space}
  ${variant({ variants: messageVariants })}
`;

export const MessageDesc = styled.p<{ isOutgoing: boolean }>`
  margin: 0;
  padding: 0 5px;
  background-color: transparent;
  color: black;
  text-align: ${({ isOutgoing }) => (isOutgoing ? 'right' : 'left')};
  font-weight: 600;
  font-size: 0.8rem;
`;

export const Time = styled.span`
  font-size: 0.6rem;
  color: ${({ theme }) => theme.colors.textSecond}; ;
`;

export const ExamplesWrap = styled(Flex)`
  max-width: 45%;
  flex-wrap: wrap;
  margin-left: auto;
  background-color: #fff;
  overflow: auto;
`;

export const MessageExample = styled.div`
  width: 100%;
  text-align: center;
  margin: ${({ theme }) => theme.space[1]}px;
  padding: ${({ theme }) => theme.space[2]}px;
  border: 1px solid ${({ theme }) => theme.colors.UIMessage};
  color: ${({ theme }) => theme.colors.UIMessage};
  border-radius: ${({ theme }) => theme.borderRadius['2']};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.UIMessage};
    color: ${({ theme }) => theme.colors.textContrast};
  }
`;

export const Online = styled.span`
  padding: 2px 5px;
  background-color: ${({ theme }) => theme.colors.UISuccess};
  color: ${({ theme }) => theme.colors.textContrast};
  border-radius: ${({ theme }) => theme.borderRadius[2]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
`;
