import styled from 'styled-components';
import { Section } from 'ui/components';

export const Wrap = styled(Section)`
  padding: 108px 0 80px;
`;

export const Card = styled.div`
  position: relative;
  height: 100%;
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
`;

export const Quotes = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
