import styled from 'styled-components';

export const Wrap = styled.div`
  margin: 60px 0;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: stretch;
  flex-wrap: wrap;
`;

export const CardTop = styled.div`
  flex: 1 0 100%;
  max-width: 100%;
  padding: 32px 38px;
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
  border-top-left-radius: ${({ theme }) => theme.borderRadius['1']};
  border-top-right-radius: ${({ theme }) => theme.borderRadius['1']};

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 20px;
  }
`;

export const CardBottom = styled.div`
  flex: 1 0 100%;
  max-width: 100%;
  padding: 24px 32px;
  background-color: ${({ theme }) => theme.colors.cardBg};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius['1']};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius['1']};
  color: ${({ theme }) => theme.colors.textContrast};
`;

export const Tag = styled.span`
  display: inline-block;
  padding: ${({ theme }) => theme.space['1']}px;
  background-color: ${({ theme }) => theme.colors.cardBg};
  color: ${({ theme }) => theme.colors.textContrast};
  border-radius: ${({ theme }) => theme.borderRadius['0']};
`;
