import * as React from 'react';
import { useClickOutside, useDisclosure } from 'hooks';
import { Input } from 'ui/components';
import { Option } from 'models';
import { ArrowSmall } from 'images/icons';
import { Container, Icon, Item, OptionList } from './styled';

interface Props {
  value: string;
  name: string;
  placeholder: string;
  options: Array<Option>;
  onChange: (e: any) => void;
}

export const Select: React.FC<Props> = ({
  options,
  onChange,
  value,
  name,
  placeholder,
}) => {
  const selectRef = React.useRef();
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure();

  const setValue = (selected: Option) => {
    const syntheticEvent = { target: { value: selected.id, name } };
    onChange(syntheticEvent);
    onClose();
  };

  const getLabel = () => options?.find(item => item.id === value)?.name;

  useClickOutside(selectRef, onClose);

  return (
    <Container isOpen={isOpen} ref={selectRef}>
      <Input
        value={getLabel()}
        placeholder={placeholder}
        onClick={onToggle}
        name={name}
        readOnly
      />
      <Icon onClick={onToggle}>
        <ArrowSmall />
      </Icon>
      <OptionList isOpen={isOpen}>
        {options.map(option => (
          <Item key={option.id} onClick={() => setValue(option)}>
            {option.name}
          </Item>
        ))}
      </OptionList>
    </Container>
  );
};
