import styled from 'styled-components';
import { Grid, Text, Section } from 'ui/components';

export const Wrap = styled(Section)`
  background: linear-gradient(90deg, #dfe6fa 0%, #eceef7 100%);
`;

export const Overlay = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius['1']};
  box-shadow: 0 8px 40px rgba(81, 94, 149, 0.15);
  overflow: hidden;
`;
export const Header = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  color: ${({ theme }) => theme.colors.textContrast};
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoints['0']}) {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.textDefault};
  }
`;

export const Tab = styled(Text)<{ isActive: boolean }>`
  @media (max-width: ${({ theme }) => theme.breakpoints['0']}) {
    position: relative;
    font-size: ${({ theme }) => theme.fontSizes['1']};
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 80%;
      height: 2px;
      transform: translateX(-50%);
      opacity: ${({ isActive }) => (isActive ? 1 : 0)};
      background-color: ${({ theme }) => theme.colors.cardBg};
      transition: opacity 0.25s ease;
    }
  }
`;
