import styled from 'styled-components';
import { variant } from 'styled-system';
import { Flex } from '../Flex';

interface Props {
  variant: string;
}

const variants = {
  success: {
    bg: 'UISuccessBg',
  },
  error: {
    bg: 'UIErrorBg',
  },
};

export const Status = styled(Flex)<Props>`
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${variant({ variants })}
`;
