import * as React from 'react';
import { Container, Grid, Flex, Box, Text, Link } from 'ui/components';
import {
  Logo,
  IconPhone,
  IconMail,
  IconBoleto,
  IconVisa,
  IconMasterCard,
  IconAura,
  IconPaypal,
  IconElo,
  IconPayoneer,
  IconHypercard,
} from 'images/icons';
import { Wrap } from './styled';

export const Footer = () => (
  <Wrap>
    <Container>
      <Grid gridTemplateColumns={['1fr', '1fr 2fr 1fr']}>
        <Box>
          <Logo />
          <Text mt={3}>© {new Date().getFullYear()} Studybay todosos direitos reservados</Text>
        </Box>
        <Box>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr']}>
            <Flex mb={2}>
              <IconPhone />
              <Link ml={2} href="tel:+55 11 4680-2890">+55 11 4680-2890</Link>
            </Flex>
            <Flex mb={2}>
              <IconMail />
              <Link ml={2} href="mailto:suporte@mystudybay.com.br">suporte@mystudybay.com.br</Link>
            </Flex>
          </Grid>
          <Text mt={4}>
            O nosso SAC (serviço de atendimento ao cliente) está disponível nos
            dias úteis das 6h às 21h, e aos Sábados e Domingos das 12h às 18h.
          </Text>
          <Link href="/" mb={2}>Politica de Privacidade</Link>
        </Box>
        <Box>
          <Text mt={0} color="textContrast">Aceitamos:</Text>
          <Grid gridTemplateColumns={'1fr 1fr 1fr 1fr'} mt={4}>
            <IconBoleto />
            <IconVisa />
            <IconMasterCard />
            <IconPaypal />
            <IconAura />
            <IconHypercard />
            <IconElo />
            <IconPayoneer />
          </Grid>
        </Box>
      </Grid>
    </Container>
  </Wrap>
);
