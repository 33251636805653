export const isBrowser = typeof window !== 'undefined';

export const getCookie = (name: string) => {
  if (isBrowser) {
    const cookies = `; ${document.cookie}`;
    const parts = cookies.split(`; ${name}=`);
    return parts.length === 2 ? parts.pop().split(';').shift() : null;
  }
};

interface CookieProps {
  name: string;
  value: string | number;
  expire: number;
}
export const setCookie = ({ name, value, expire }: CookieProps) => {
  if (isBrowser) {
    let date = new Date();
    date.setTime(date.getTime() + expire * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = name + '=' + value + '; ' + expires + '; path=/';
  }
};

export const sendPost = async (url: string, body?: Object) => {
  // try {
  //   const response = await fetch(`https://test.mystudybay.com.br${url}/`, {
  //     method: 'POST',
  //     headers: {
  //       Accept: '*/*',
  //       Referer: 'https://test.mystudybay.com.br/',
  //       'Access-Control-Allow-Origin': '*',
  //       'Content-type': 'application/json',
  //       'X-Requested-With': 'XMLHttpRequest',
  //       'Access-Control-Request-Headers': 'Location',
  //     },
  //     body: JSON.stringify({ body }),
  //   });
  //   return response.json();
  // } catch (e) {
  //   console.error(e);
  // }
};

export const validateEmail = async email => {
  if (email.length < 50 && /^\w+([\.-]?[\.+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(email)) {
    // const response = await sendPost('/ajax/checkemail', { email });
    // return response?.count === 0;
    return true;
  } else {
    return false;
  }
};

export const isCookieAllowed = (name: string) => {
  const allowedCookies = getCookie('allowed');
  return allowedCookies?.includes(name);
};

export const getCurrentTime = () =>
  new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

export const sortArrayByStringProp = (array, prop) => {
  return array.sort((a, b) =>
    new Intl.Collator('pt-BR').compare(a[prop], b[prop])
  );
};

export const sendAnalytics=(category:string, action:string, label:string, fbEvent:string) =>{
  if (isCookieAllowed('gtm')){
    // @ts-ignore
    const { ga, fbq } = window;
    ga('send', 'event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    });
    fbq('trackCustom', fbEvent)
  }
}