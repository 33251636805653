import styled from 'styled-components';
import { variant } from 'styled-system';

interface Props {
  variant?: string;
  isOnline?: boolean;
}

const variants = {
  sm: {
    width: '40px',
    height: '40px',
  },
};

export const Avatar = styled.div<Props>`
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }

  ${variant({ variants })}

  &:before {
    content: ${({ isOnline }) => (isOnline ? '" "' : 'unset')};
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.colors.UISuccess};
    border-radius: 50%;
    z-index: 1;
  }
`;
