import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: -120px;
  height: 260px;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
  border-radius: ${({ theme }) => theme.borderRadius['2']};
  box-shadow: 0 0 40px rgba(81, 94, 149, 0.2);

  @media (max-width: ${({ theme }) => theme.breakpoints['1']}) {
    margin-top: 30px;
    height: auto;
  }
  @media (max-height: 750px) {
    margin-top: 30px;
    height: auto;
  }
`;

export const Icon = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.backgroundAccentedLight};
`;
