import * as React from 'react';
import { Container, Grid, Flex, Text } from 'ui/components';
import { guarantees } from 'content/guarantees';
import { Wrap, Icon } from './styled';

export const Guarantees = () => {
  return (
    <Container>
      <Wrap>
        <Grid gridGap={4} gridTemplateColumns={['1fr', '1fr 1fr 1fr']} alignItems="start">
          {guarantees.map(item => (
            <Grid key={item.title} gridTemplateColumns={'1fr'}>
              <Flex alignItems="center">
                <Icon>{item.icon}</Icon>
                <Text ml={3} level={4} color="textDark" fontWeight={600}>
                  {item.title}
                </Text>
              </Flex>
              <Text>{item.text}</Text>
            </Grid>
          ))}
        </Grid>
      </Wrap>
    </Container>
  );
};
