import * as React from 'react';
import { Form } from 'components';
import { Container, Box, Flex, Heading, Text } from 'ui/components';
import { Wrap } from './styled';

export const BottomForm: React.FC = () => {
  return (
    <Wrap>
      <Container>
        <Flex>
          <Box width={[1, 2 / 3]}>
            <Heading level="2" color="textContrast">
              Conheça melhor nossos especialistas, busque ajuda agora mesmo!
            </Heading>
            <Form typeForm="br_ChatBotFormCustomer_2"/>
          </Box>
        </Flex>
      </Container>
    </Wrap>
  );
};
