import styled from 'styled-components';

interface Props {
  isActive: boolean;
}

export const Tab = styled.span<Props>`
  display: inline-block;
  margin-right: 15px;
  padding: 0.625rem 1rem;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.textContrast : theme.colors.textDefault};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.backgroundDark : theme.colors.backgroundDefault};
  border-radius: ${({ theme }) => theme.borderRadius['0']};
  line-height: 1;
  cursor: pointer;
`;
