import * as React from 'react';
import { isBrowser } from 'helpers';

const breakpoint = 820;

export const useMedia = () => {
  if (!isBrowser) {
    return;
  }
  const [isMobile, setIsMobile] = React.useState<boolean>(
    window.innerWidth < breakpoint
  );

  const resizeListener = () => {
    setIsMobile(window.innerWidth < breakpoint);
  };
  React.useEffect(() => {
    window.addEventListener('resize', resizeListener, {passive: true});
    return () => document.removeEventListener('resize', resizeListener);
  }, []);

  return isMobile;
};
