import { sendAnalytics } from './index';

export const useChatAnalytics = (msgCount: number) => {
  switch (msgCount) {
    case 0:
      sendAnalytics(
        'serAction',
        'firstMessageSend',
        'success',
        'firstMessageSend'
      );
      break;
    case 4:
      sendAnalytics(
        'serAction',
        'fifthMessageSend',
        'success',
        'fifthMessageSend'
      );
      break;
    case 9:
      sendAnalytics(
        'serAction',
        'tenthMessageSend',
        'success',
        'tenthMessageSend'
      );
      break;
  }
};
