import styled from 'styled-components';

export const Textarea = styled.textarea`
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.backgroundDefault};
  border: 1px solid ${({ theme }) => theme.colors.borderDefault};
  color: ${({ theme }) => theme.colors.textDark};
  border-radius: ${({ theme }) => theme.borderRadius[0]};
  outline: none;
  resize: none;
  &:focus {
    box-shadow: 0 0 3pt 2pt ${({ theme }) => theme.colors.borderFocused};
  }
`;
