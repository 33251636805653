import * as React from 'react';
import { IconEquipe, IconGaranties, IconSecure } from 'images/icons'

export const guarantees = [
  {
    icon: <IconGaranties />,
    title: 'Garantias que fazem toda diferença',
    text: 'Os seus trabalhos acadêmicos são a nossa prioridade. Todos os projetos são analisados individualmente e serão entregues no prazo determinado. Após a entrega você terá 20 dias de garantia para quaisquer correções.',
  },
  {
    icon: <IconEquipe />,
    title: 'Equipe qualificada e experiente',
    text: 'Nosso time de especialistas passa por um rigoroso processo seletivo. Prezamos sempre pela qualidade, ou seja, somente especialistas altamente competentes cuidarão do seu projeto acadêmico.',
  },
  {
    icon: <IconSecure />,
    title: 'Segurança completa para você',
    text: 'Cuidamos da sua privacidade e segurança mesmo que você esteja distante. Fornecemos atendimento 24h para nossos usuários. Você é protegido do início ao fim.',
  },
];