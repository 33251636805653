import styled from 'styled-components';
import { variant } from 'styled-system';

export const variants = {
  secondary: {
    color: 'cardBg',
    background: 'transparent',
    border: '3px solid',
    borderColor: 'cardBg',
  },
};

export const Button = styled.button<{ variant?: string }>`
  display: block;
  width: 100%;
  padding: 1rem;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.textContrast};
  background: linear-gradient(180deg, #7169f0 0%, #4258e1 100%);
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius['0']};
  cursor: pointer;
  ${variant({ variants })}
`;
