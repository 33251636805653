import styled from 'styled-components';
import {
  variant,
  space,
  color,
  typography,
  SpaceProps,
  ColorProps,
  TypographyProps,
} from 'styled-system';
import { typography as variants } from 'ui/typography';

interface Props extends SpaceProps, ColorProps, TypographyProps {
  children: React.ReactNode;
  level?: number | number[];
}

export const Text = styled.p<Props>(
  variant({
    variants,
    prop: 'level',
  }),
  space,
  color,
  typography
);
