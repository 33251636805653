import styled from 'styled-components';
import { space, SpaceProps, } from 'styled-system';

interface Props extends SpaceProps{};

export const Link = styled.a<Props>`
  display: inline-block;
  color: ${({ theme }) => theme.colors.textContrast};
  opacity: .7;
  text-decoration: none;
  ${space}
  &:hover{
    opacity: 1;
    transition: opacity .25s;
  }
`;
