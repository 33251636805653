import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints['0']}) {
    max-width: 100%;
    padding: 0 10px;
  }
`;
