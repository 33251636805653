import styled from 'styled-components';
import Slider from 'react-slick';

export const CustomSlider = styled(Slider)`
  position: relative;
  margin: 20px 0;
  padding-bottom: 60px;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  @media(max-width: ${({theme})=> theme.breakpoints[0]}){
    padding-bottom: 0;
    margin: 0 -10px;
  }

  &.slick-initialized .slick-slide {
    display: block;
  }
  .slick-list {
    overflow: hidden;
    margin: 0 -20px;
    padding: 0;
    @media(max-width: ${({theme})=> theme.breakpoints[0]}){
      margin: 0;
    }
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-slide {
    display: none;
    min-height: 1px;
    margin: 0 20px;
    & > div {
      height: 100%;
    }
    @media(max-width: ${({theme})=> theme.breakpoints[0]}){
      margin: 0;
      padding: 5px;
    }
  }
  .slick-arrow {
    position: absolute;
    height: 20px;
    bottom: 5px;
    cursor: pointer;
    &.slick-next {
      left: 85px;
    }
    &.slick-prev {
      transform: rotate(-180deg);
    }
    &.slick-disabled {
      opacity: 0.7;
    }
  }
`;
