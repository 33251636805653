import * as React from 'react';
import styled from 'styled-components';
import { variant, space, color, ColorProps, SpaceProps } from 'styled-system';
import { typography as variants } from 'ui/typography';

interface Props extends ColorProps, SpaceProps {
  level: string;
  as?: React.FC;
}

const HeadingBase: React.FC<Props> = ({
  level,
  as: Component = `h${level}`,
  ...props
}) => <Component {...props} />;

export const Heading = styled(HeadingBase)(
  {
    margin: 0,
  },
  variant({
    variants,
    prop: 'level',
  }),
  space,
  color
);
