import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { BlockWithChat } from 'components/BlockWithChat';
import { useWorkingHours } from 'hooks';
import {
  Carousel,
  Section,
  Container,
  Grid,
  Box,
  Flex,
  Text,
  Tab,
  Status,
  Avatar,
  Heading,
} from 'ui/components';
import { IconStar, IconSuccess } from 'images/icons';
import { Card, CardTop, CardBottom, Tag } from './styled';

const tabs = [
  {
    id: 2,
    title: 'Redacao',
  },
  {
    id: 3,
    title: 'Trabalho de graduacao',
  },
];

interface Example {
  id: string;
  name: string;
  tag: string;
  score: string;
  text: string;
  title: string;
  cost: string;
  duration: string;
  category: number;
}

interface Examples {
  allExamplesJson: {
    nodes: Example[];
  };
  file: { childImageSharp: { fluid: { src: string } } };
}

export const Examples: React.FC = () => {
  const {
    allExamplesJson: { nodes: examples },
    file,
  }: Examples = useStaticQuery(graphql`
    query {
      allExamplesJson {
        nodes {
          id
          name
          tag
          score
          text
          title
          cost
          duration
          category
        }
      }
      file(name: { eq: "tutor-example" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `);

  const [currentSlides, setCurrentSlides] = React.useState(examples);
  const [activeTabId, setActiveTabId] = React.useState(0);
  const isWorkHours = useWorkingHours();

  const filterSlidesById = id => {
    const filteredSlides = examples.filter(item => item.category === id);
    setCurrentSlides(filteredSlides);
  };

  const onTabClick = id => {
    filterSlidesById(id);
    setActiveTabId(id);
  };

  return (
    <Section>
      <Container>
        <Heading level="2" mb={4}>
          Exemplos de trabalho concluido
        </Heading>
        <BlockWithChat>
          {tabs.map(item => (
            <Tab
              key={item.id}
              onClick={() => onTabClick(item.id)}
              isActive={activeTabId === item.id}
            >
              {item.title}
            </Tab>
          ))}
          <Carousel slidesToShow={isWorkHours ? 2 : 3}>
            {currentSlides?.map(item => {
              return (
                <Card key={item.id}>
                  <CardTop>
                    <Flex alignItems="start">
                      <Box minHeight={['unset', '100px']}>
                        <Text level={5} m={0}>
                          {item.title}
                        </Text>
                        <Text mt={0} color="textSecond">
                          {item.text}
                        </Text>
                      </Box>
                      <Status ml="auto" variant="success" flex="0 0 auto">
                        <IconSuccess />
                      </Status>
                    </Flex>
                    <Flex alignItems="center" mt={[2, 5]}>
                      <Box flex="1 0 56px" maxWidth="56px">
                        <Avatar>
                          <img
                            src={file.childImageSharp.fluid.src}
                            alt={item.title}
                          />
                        </Avatar>
                      </Box>
                      <Box ml={[2, 3]} flex="0 1 auto">
                        <Tag>{item.tag}</Tag>
                        <Text level={[5, 4]} m={0} mt={1}>
                          {item.name}
                        </Text>
                        <Flex>
                          {[...Array(item.score)].map(() => (
                            <IconStar />
                          ))}
                          <Text m={0} ml={2}>
                            {item.score}.0
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                  </CardTop>
                  <CardBottom>
                    <Grid gridTemplateColumns={['1fr', '1fr 1fr']}>
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        flexWrap={['nowrap', 'wrap']}
                      >
                        <Text m={0}>Custo de trabaho</Text>
                        <Text level={3} m={0}>
                          {item.cost}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        flexWrap={['nowrap', 'wrap']}
                      >
                        <Text m={0}>Prazo de entrega</Text>
                        <Text level={3} m={0}>
                          {item.duration}
                        </Text>
                      </Flex>
                    </Grid>
                  </CardBottom>
                </Card>
              );
            })}
          </Carousel>
        </BlockWithChat>
      </Container>
    </Section>
  );
};
