import { Theme } from '../models';

const palette = {
  white: '#fff',
  black: '#000',
  violet: {
    50: '#E8EAF2',
    100: '#ECEEF7',
    150: '#8E8FAE',
    200: '#646B94',
    300: '#6160e9',
    400: '#4258E1',
    500: '#32375D',
    main: '#6273B7',
    dark: '#424478',
  },
  green: {
    light: '#C8FCD3',
    main: '#36CD4D',
  },
  red: {
    light: '#FFDCDD',
    main: '#EA6666',
  },
};

export const theme: Theme = {
  colors: {
    backgroundDefault: palette.white,
    backgroundAccented: palette.violet[100],
    backgroundDark: palette.violet.dark,
    backgroundAccentedLight: palette.violet[50],
    overlayBg: palette.violet[500],
    cardBg: palette.violet.main,
    textDefault: palette.violet.main,
    textContrast: palette.white,
    textSecond: palette.violet[150],
    textDark: palette.violet.dark,
    borderDefault: palette.violet[50],
    borderFocused: palette.violet.main,
    UIMessage: palette.violet[300],
    UIError: palette.red.main,
    UISuccess: palette.green.main,
    UIErrorBg: palette.red.light,
    UISuccessBg: palette.green.light,
  },
  fontFamily: ['Proxima', 'sans-serif'].join(','),
  fontSizes: ['0.85rem', '1.125rem', '1.25rem', '1.5rem', '2rem', '3rem', '4.5rem'],
  borderRadius: ['4px', '8px', '32px'],
  space: [0, 4, 8, 16, 32, 64, 128],
  breakpoints: ['820px', '1200px'],
};
